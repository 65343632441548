import React from "react";
import Paragraph from "../components/Paragraph";
import LongText from "../components/LongText";

const IntroText: React.FC = () => {
  return (
    <LongText
      background={"white"}
      titel={"Hämostaseologischer Intensivworkshop"}
    >
      <Paragraph>
        Liebe <strong>H</strong>ämostase <strong>I</strong>n <strong>C</strong>ritical <strong>C</strong>are - Interessierte
      </Paragraph>
      <Paragraph>
        Nach der «historischen» Startveranstaltung des HICC® 2006 freuen wir uns, mittlerweile das 11. HICC® Meeting im neuen Format in St. Gallen durchzuführen.
      </Paragraph>
      <Paragraph>
        Am 26. und 27.04.2024 versuchen wir wiederum neue Erkenntnisse, Daten und Ideen kritisch zu präsentieren und zu diskutieren.
      </Paragraph>
      <Paragraph>
        Neben «klassischen» Vorträgen mit anschliessender Diskussionsmöglichkeit bieten wir auch genügend Raum für interaktive Falldiskussionen.<br />
        Dabei soll auch dem Networking-Aspekt mit regem Austausch in entspannter Atmosphäre Rechnung
        getragen werden. Wegweisende Projekte können so entwickelt werden.
      </Paragraph>
      <Paragraph>
        Wir möchten Sie herzlich zu diesem interdisziplinären Intensivworkshop unter der Schirmherrschaft
        der Gesellschaft für Thrombose und Hämostaseforschung (GTH) einladen und freuen uns darauf, Sie
        in St. Gallen am 26. und 27. April 2024 persönlich begrüssen zu dürfen.
      </Paragraph>
    </LongText>
  );
};

export default IntroText;
